<template>
  <div>
    <el-card shadow="never">
      <div slot="header">
        <span class="iconfont icon-PlacesInfo">&nbsp;系统日志</span>
      </div>
      <el-table :data="list" size="mini">
        <el-table-column prop="mobile" label="文件名称"></el-table-column>
        <el-table-column prop="mobile" label="文件大小"></el-table-column>
        <el-table-column prop="mobile" label="修改日期"></el-table-column>
        <el-table-column prop="mobile" label="操作"></el-table-column>
      </el-table>
      <div class="page_right">
        <el-pagination
          background
          layout="total,prev, pager, next ,jumper"
          :total="list.length"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      pageSize: 10,
      currentPage: 1
    };
  },
  methods: {
    //选择第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    }
  }
};
</script>
<style lang='scss'>
</style>